<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs parent="akademik.data" sub_parent="akademik.sebaran" />
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              PETA SEBARAN MAHASISWA
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1 text-left">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="hello" ref="chartdiv" id="chartdiv"></div>
                    <br />
                    <small
                      ><em
                        >*Belum terdata :
                        {{ belumLengkap.toLocaleString("en-US") }}</em
                      ></small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-left" elevation="3">
            <v-card-title class="text-body-2 text-md-body-1 font-weight-bold">
              <v-container>
                <v-row>
                  <v-col cols="12" md="7" lg="7">
                    Gorontalo :
                    {{ mhsGorontalo.toLocaleString("en-US") }}
                    Mahasiswa<br /><br />
                    <div id="chart2">
                      <apexchart
                        type="bar"
                        height="320"
                        :options="chartOptions2"
                        :series="series2"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" lg="5">
                    Perbandingan Dengan Luar Gorontalo
                    <div id="chart">
                      <apexchart
                        type="pie"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-left" elevation="3">
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <strong>Detail Sebaran Mahasiswa</strong><br />
                    <v-data-table
                      :headers="headers"
                      :items="jumlahMahasiswa"
                      :search="search"
                      mobile-breakpoint="0"
                    >
                      <template v-slot:item.nomor="{ item }">
                        {{ jumlahMahasiswa.indexOf(item) + 1 }}
                      </template>
                      <template v-slot:item.kabupaten="{ item }">
                        <span
                          v-if="item.kabupaten == ''"
                          class="text-caption"
                          style="word-break: break-word"
                          >BELUM TERDATA</span
                        >
                        <span
                          v-else
                          class="text-caption"
                          style="word-break: break-word"
                          >{{ item.kabupaten }}</span
                        >
                      </template>
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          :label="$t('tabel.cari')"
                          class="mx-4 pt-4"
                        ></v-text-field
                      ></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_indonesiaHigh from "@amcharts/amcharts5-geodata/indonesiaHigh";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated.js";

import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";

export default {
  name: "SebaranMahasiswa",
  components: {
    MyBreadcrumbs,
  },
  data: () => ({
    belumLengkap: 0,
    mhsGorontalo: 0,

    series: [],
    chartOptions: [],

    series2: [],
    chartOptions2: [],

    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Nama Kota / Kab", value: "kabupaten" },
      { text: "Jumlah Mahasiswa", value: "count" },
    ],

    jumlahMahasiswa: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaAktifByProvinsi();
      this.countMahasiswaAktifByPerbandinganDomisili();
      this.countMahasiswaAktifByGorontalo();
      this.countMahasiswaAktifByKabupaten();
    },

    countMahasiswaAktifByProvinsi() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByProvinsi")
        .then((response) => {
          this.$vloading.hide();
          var labelData = response.data;

          var bL = labelData.filter(function (item) {
            return item.polygonId == null;
          });
          this.belumLengkap = bL[0].name;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktifByPerbandinganDomisili() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByPerbandinganDomisili")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;
          // console.log(response.data.data);

          this.mhsGorontalo = data.count_gorontalo;

          this.series = [data.count_gorontalo, data.count_lainnya];
          this.chartOptions = {
            chart: {
              type: "pie",
            },
            legend: {
              position: "bottom",
            },
            colors: ["#F44336", "#2196F3"],
            labels: ["Gorontalo", "Luar Gorontalo"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series = [];
          this.chartOptions = [];
        });
    },

    countMahasiswaAktifByGorontalo() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByGorontalo")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const kabupaten = data.map((key) => {
            return key.kabupaten;
          });

          this.series2 = [
            {
              name: "Jumlah Mahasiswa",
              data: count,
            },
          ];
          this.chartOptions2 = {
            chart2: {
              type: "bar",
            },
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
            xaxis: {
              categories: kabupaten,
              labels: {
                show: false,
                style: {
                  colors: [
                    "#4CAF50",
                    "#FF9800",
                    "#F44336",
                    "#2196F3",
                    "#9C27B0",
                    "#795548",
                    "#E91E63",
                    "#607D8B",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series2 = [];
          this.chartOptions2 = [];
        });
    },

    countMahasiswaAktifByKabupaten() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByKabupaten")
        .then((response) => {
          this.$vloading.hide();
          this.jumlahMahasiswa = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },
  },

  mounted() {
    // Create root and chart
    var root = am5.Root.new("chartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoNaturalEarth1(),
      })
    );

    // Create polygon series
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_indonesiaHigh,
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color("#82C2E0"),
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color("#ffc107"),
    });

    // Create series for labels
    var pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        polygonIdField: "polygonId",
      })
    );

    pointSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          fontSize: 10,
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{name}",
          populateText: true,
        }),
      });
    });

    // Add state labels
    polygonSeries.events.on("datavalidated", function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/countMahasiswaAktifByProvinsi")
        .then((response) => {
          var labelData = response.data;
          // console.log(response.data);
          pointSeries.data.setAll(labelData);
        })
        .catch((error) => {
          console.log(error);
          var labelData = [];
          // console.log(response);
          pointSeries.data.setAll(labelData);
        });
    });
  },

  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>