<template>
  <div>
    <center>
      <v-img src="/img/logo/segera-hadir.gif" style="max-width: 350px" />
    </center>
  </div>
</template>
<script>
export default {
  name: "ComingSoon",
};
</script>