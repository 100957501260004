<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs parent="akademik.jumlah" sub_parent="Jurusan" />
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              {{ $t("akademik.jml_mhs_jurusan").toUpperCase() }}
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1 text-left">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-list subheader two-line outlined>
                      <template v-for="(dt, index) in itemsJurusan">
                        <v-list-item :key="dt.kode_jurusan">
                          <v-list-item-avatar
                            class="text-body-1 text-md-h6"
                            size="60"
                            :color="warna"
                            :style="'color:' + warna_text"
                          >
                            {{ dt.count.toLocaleString("en-US") }}
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="text-body-2 text-md-h6">{{
                              dt.jurusan
                            }}</v-list-item-title>

                            <v-list-item-subtitle
                              class="text-caption text-md-body-2"
                              >Jumlah Mahasiswa</v-list-item-subtitle
                            >
                            <span class="hidden-sm-and-up">
                              <!-- <v-btn
                                color="primary"
                                tile
                                x-small
                                :to="
                                  `/${$i18n.locale}` +
                                  '/jumlah-mahasiswa/jurusan/' +
                                  dt.kode_jurusan
                                "
                              >
                                <v-icon left x-small>mdi-information</v-icon
                                >Detail
                              </v-btn> -->
                            </span>
                          </v-list-item-content>

                          <v-list-item-action class="hidden-xs-only">
                            <!-- <v-btn
                              color="primary"
                              tile
                              small
                              :to="
                                `/${$i18n.locale}` +
                                '/jumlah-mahasiswa/jurusan/' +
                                dt.kode_jurusan
                              "
                            >
                              <v-icon left>mdi-information</v-icon>Detail
                            </v-btn> -->
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="index < itemsJurusan.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";

export default {
  name: "DetailMahasiswaFakultas",
  components: {
    MyBreadcrumbs,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    sub_parent: "",
    itemsJurusan: [],
    warna: "",
    warna_text: "",
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaAktifByFakultas();
    },

    countMahasiswaAktifByFakultas() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByFakultas/" + this.$route.params.id)
        .then((response) => {
          this.$vloading.hide();
          this.itemsJurusan = response.data.data;
          this.sub_parent = response.data.data[0].fakultas;
          if (
            this.sub_parent == "FAKULTAS TEKNIK" ||
            this.sub_parent == "FAKULTAS SASTRA DAN BUDAYA" ||
            this.sub_parent == "FAKULTAS PERTANIAN" ||
            this.sub_parent == "FAKULTAS KEDOKTERAN"
          ) {
            this.warna_text = "white";
          } else {
            this.warna_text = "black";
          }
          this.warna = response.data.data[0].warna;
          // console.log(this.itemsJurusan);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error);
        });
    },
  },
};
</script>