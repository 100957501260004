<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs parent="akademik.data" sub_parent="akademik.lulusan" />
        </v-col>

        <v-col cols="12" md="3" lg="3">
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan.toLocaleString("en-US") }}
            </v-card-title>
            <v-card-actions
              style="background-color: white"
              class="justify-center text-caption text-md-body-2 pt-5"
            >
              <div id="chart">
                <apexchart
                  type="pie"
                  width="320"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-actions> </v-card
          ><br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan Tahun {{ tahun }}
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan1Tahun.toLocaleString("en-US") }}
            </v-card-title> </v-card
          ><br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan 5 Tahun Terakhir
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan5Tahun.toLocaleString("en-US") }}
            </v-card-title>
          </v-card>
          <!-- <br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Lulusan Cum Laude 5 Tahun Terakhir
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              4.330
            </v-card-title>
          </v-card> -->
        </v-col>
        <v-col cols="12" md="9" lg="9">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              TOTAL LULUSAN PERTAHUN
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div id="chart2">
                      <apexchart
                        type="line"
                        height="300"
                        :options="chartOptions2"
                        :series="series2"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title> </v-card
          ><br />
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              TOTAL LULUSAN FAKULTAS
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div id="chart3">
                      <apexchart
                        type="bar"
                        height="300"
                        :options="chartOptions3"
                        :series="series3"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";
export default {
  name: "LulusanMahasiswa",
  components: {
    MyBreadcrumbs,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    totalLulusan: 0,
    totalLulusan1Tahun: 0,
    totalLulusan5Tahun: 0,

    series: [],
    chartOptions: [],

    series2: [],
    chartOptions2: [],

    series3: [],
    chartOptions3: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaLulus();
      this.countMahasiswaLulusByTahun();
      this.countMahasiswaLulusByJenjang();
      this.countMahasiswaLulusByFakultas();
    },

    countMahasiswaLulus() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulus")
        .then((response) => {
          this.$vloading.hide();
          this.totalLulusan = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaLulusByTahun() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByTahun")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;

          const count = data.map((key) => {
            return key.count;
          });

          const tahun = data.map((key) => {
            return key.tahun;
          });

          this.series2 = [
            {
              name: "Total Lulusan",
              data: count,
            },
          ];
          this.chartOptions2 = {
            chart2: {
              height: 300,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            markers: {
              size: 5,
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: tahun,
            },
          };

          this.totalLulusan1Tahun = data.pop().count;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series2 = [];
          this.chartOptions2 = [];
        });
    },

    countMahasiswaLulusByJenjang() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByJenjang")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;

          const count = data.map((key) => {
            return key.count;
          });

          const jenjang = data.map((key) => {
            return key.jenjang;
          });

          this.series = count;
          this.chartOptions = {
            chart: {
              type: "pie",
            },
            colors: [
              "#4CAF50",
              "#F44336",
              "#FF9800",
              "#9C27B0",
              "#2196F3",
              "#607D8B",
              "#E91E63",
              "#795548",
            ],
            legend: {
              position: "top",
            },
            dataLabels: {
              enabled: true,
            },
            labels: jenjang,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "top",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series = [];
          this.chartOptions = [];
        });
    },

    countMahasiswaLulusByFakultas() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByFakultas")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const fakultas = data.map((key) => {
            return key.fakultas;
          });

          this.series3 = [
            {
              name: "Total Lulusan",
              data: count,
            },
          ];
          this.chartOptions3 = {
            chart3: {
              height: 350,
              type: "bar",
            },
            colors: [
              "#D32F2F",
              "#C2185B",
              "#7B1FA2",
              "#0288D1",
              "#512DA8",
              "#0097A7",
              "#00796B",
              "#388E3C",
              "#AFB42B",
              "#f57c00",
              "#5D4037",
              "#455A64",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
            xaxis: {
              categories: fakultas,
              labels: {
                show: false,
                style: {
                  colors: [
                    "#D32F2F",
                    "#C2185B",
                    "#7B1FA2",
                    "#0288D1",
                    "#512DA8",
                    "#0097A7",
                    "#00796B",
                    "#388E3C",
                    "#AFB42B",
                    "#f57c00",
                    "#5D4037",
                    "#455A64",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series3 = [];
          this.chartOptions3 = [];
        });
    },
  },
};
</script>