<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs
            parent="kementerian.layanan"
            sub_parent="kementerian.laporkerma"
          />
        </v-col>

        <v-col cols="12">
          <MyAlert deskripsi="Deskripsi" judul="kementerian.laporkerma" url="#" />
        </v-col>

        <v-col cols="12">
          <ComingSoon />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";
import MyAlert from "../../components/MyAlert.vue";
import ComingSoon from "../../components/ComingSoon.vue";

export default {
  name: "Laporkerma",
  components: {
    MyBreadcrumbs,
    MyAlert,
    ComingSoon,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {},
  },
};
</script>