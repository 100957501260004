var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"ma-4",attrs:{"cols":"12"}},[_c('MyBreadcrumbs',{attrs:{"parent":"akademik.data","sub_parent":"akademik.jumlah"}})],1),_vm._l((_vm.itemsFakultas),function(dt){return _c('v-col',{key:dt.fakultas,attrs:{"cols":"12","md":"3","lg":"3"}},[(dt.fakultas == 'PASCASARJANA')?_c('span',[_c('MyCard',{attrs:{"color":dt.warna,"title":dt.count.toLocaleString('en-US'),"bottom_color":dt.warna2,"actions":dt.fakultas,"icon":"mdi-account-group"}})],1):(
            dt.fakultas == 'FAKULTAS MATEMATIKA DAN ILMU PENGETAHUAN ALAM'
          )?_c('span',[_c('MyCard',{attrs:{"color":dt.warna,"title":dt.count.toLocaleString('en-US'),"bottom_color":dt.warna2,"actions":"MATEMATIKA DAN IPA","icon":"mdi-account-group"}})],1):_c('span',[_c('MyCard',{attrs:{"color":dt.warna,"title":dt.count.toLocaleString('en-US'),"bottom_color":dt.warna2,"actions":dt.fakultas.substring(9),"icon":"mdi-account-group"}})],1)])}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"text-center",attrs:{"elevation":"3"}},[_c('v-card-actions',{staticClass:"\n              justify-center\n              text-body-1 text-md-h6\n              font-weight-bold\n              white--text\n            ",staticStyle:{"background-color":"#1e88e5"}},[_vm._v(" GRAFIK JUMLAH MAHASISWA PERTAHUN ")]),_c('v-card-title',{staticClass:"text-body-2 text-md-body-1"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"8"}},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"area","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jumlahMahasiswa,"items-per-page":5,"mobile-breakpoint":"0","sort-by":"angkatan","sort-desc":"true","footer-props":{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                    }}})],1)],1)],1)],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }