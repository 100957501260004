<template>
  <div>
    <!-- <v-card :to="'/category/' + category.slug" v-if="category.slug"> -->
    <!-- <v-img
        :src="getImage('/categories/' + category.image)"
        class="white--text"
      > -->
    <!-- <v-card-title
          class="fill-height align-end"
          v-text="category.name"
        ></v-card-title> -->
    <!-- </v-img> -->
    <!-- </v-card> -->
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-container class="mb-2" grid-list-sm>
      <v-subheader v-if="category != ''">
        Menampilkan produk untuk "{{ category }}"
      </v-subheader>
      <v-subheader v-else>Belum ada produk untuk kategori ini </v-subheader>
      <v-layout wrap>
        <v-flex v-for="product in products" :key="`product-` + product.product_id" xs6 sm6 md3 lg3 class="pa-1 pa-md-2 pa-lg-2">
          <menu-item :product="product" />
        </v-flex>
      </v-layout>
      <!-- <v-row align="center">
        <v-col
          cols="12"
          sm="6"
          md="3"
          v-for="product in products"
          :key="`product-` + product.product_id"
        >
          <menu-item :product="product" />
        </v-col>
      </v-row> -->
    </v-container>
    <!-- <template>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </template> -->
  </div>
</template>

<script>
export default {
  name: "Category",
  components: {
    MenuItem: () =>
      import(/* webpackChunkName: "menu-item" */ "@/components/MenuItem.vue"),
  },
  data: () => ({
    category: "", // objek category
    products: [], // daftar buku pada category tersebut
    // page: 0,
    // lengthPage: 0,
  }),
  created() {
    this.go();
  },
  methods: {
    go() {
      this.$vloading.show();
      let { slug } = this.$route.params;
      let url = "/all_product/category/" + slug;
      // url = url + "?page=" + this.page;
      // url = encodeURI(url);
      this.axios
        .get(url)
        .then((response) => {
          this.$vloading.hide();
          this.$vloading.hide();
          let { data } = response;
          this.products = data;
          console.log(data);
          this.category = data[0].category;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.$vloading.hide();
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>