<template>
  <div>
    <v-alert
      color="primary"
      border="left"
      elevation="3"
      colored-border
      icon="mdi-information"
      prominent
    >
      <div class="text-subtitle-2 text-md-h6">{{ $t(judul) }}</div>
      <div style="color: grey" class="text-caption text-md-body-2">
        <span v-html="deskripsi"></span>
      </div>
      <v-divider class="my-4"></v-divider>
      <div class="text-caption text-md-body-2">
        Alamat Website :
        <a :href="url" target="_blank">{{ url }}</a>
      </div>
    </v-alert>
  </div>
</template>
<script>
export default {
  name: "MyAlert",
  props: ["judul", "deskripsi", "url"],
};
</script>
