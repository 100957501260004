<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="mx-4 mt-4">
          <div>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.layanan")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.sister")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <span style="color: #939292">Sertifikasi Dosen</span>
          </div>
        </v-col>

        <v-col cols="12">
          <h2>SERTIFIKASI DOSEN</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
          <v-card :loading="isLoading">
            <!-- <v-card-actions
              style="background-color: #990000"
              class="
                justify-center
                text-body-1
                font-weight-bold
                white--text
              "
            >
              SERTIFIKASI DOSEN
            </v-card-actions> -->
            <v-data-table
              :headers="headers"
              :items="datas"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:item.nomor="{ item }">
                {{ datas.indexOf(item) + 1 }}
              </template>
              <!-- <template v-slot:item.akreditas="{ item }">
              <v-chip color="success" label>{{ item.akreditas }}</v-chip>
            </template> -->
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  color="teal darken-2"
                  x-small
                  class="ma-2 white--text"
                  @click="riwayat(item)"
                >
                  Riwayat
                  <v-icon right dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  :label="$t('tabel.cari')"
                  class="mx-4 pt-4"
                ></v-text-field
              ></template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </div>
</template>

<script>
export default {
  name: "Sertifikasi_dosen",
  components: {},
  data: () => ({
    isLoading: false,
    tahun: new Date().getFullYear(),
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Nama", value: "nama" },
      { text: "NIDN", value: "nidn" },
      {
        text: "Jenis Sertifikasi",
        value: "sertifikasi_dosen.jenis_sertifikasi",
      },
      { text: "Bidang Studi", value: "sertifikasi_dosen.bidang_studi" },
      {
        text: "Tahun Sertifikasi",
        value: "sertifikasi_dosen.tahun_sertifikasi",
      },
      { text: "SK Sertifikasi", value: "sertifikasi_dosen.sk_sertifikasi" },
      //   { text: "Aksi", value: "aksi" },
    ],
    datas: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getSertifikasiDosenList();
    },

    async getSertifikasiDosenList() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get("/api/sertifikasiDosenList");
        this.datas = response.data;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
