<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 v-if="filterTahun == ''">
            {{ $t("akreditas.akreditas_prodi_terkini") }}
          </h2>
          <h2 v-else>
            {{ $t("akreditas.akreditas_prodi_tahun") }} {{ filterTahun }}
            <v-btn color="primary" x-small @click="resetFilterTahun">
              <v-icon left dark x-small>mdi-close</v-icon>
              Reset Filter
            </v-btn>
          </h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header color="teal" class="white--text"
                ><b>Filter Tahun Akreditasi</b></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-form ref="form" lazy-validation>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-select
                        v-model="filterTahun"
                        :items="tahunItems"
                        dense
                        item-value="id"
                        item-text="name"
                        label="Pilih Tahun"
                        class="pt-6"
                        v-on:change="tahunChange"
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12">
                      <div class="text-center">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="submit"
                        >
                          <v-icon left dark>mdi-filter</v-icon>
                          Filter
                        </v-btn>
                      </div>
                    </v-col> -->
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="4" lg="4" v-for="dt in count" :key="dt.id">
          <MyCard
            color="teal"
            :title="dt.count"
            bottom_color="#00796B"
            :actions="'AKREDITASI ' + dt.akreditas"
            icon="mdi-seal-variant"
            text_color="white"
          />
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-actions
              style="background-color: #00796B"
              class="
                justify-center
                text-body-1
                font-weight-bold
                white--text
              "
            >
              <!-- FILTER BY : SEMUA AKREDITASI -->
            </v-card-actions>
            <v-data-table
              :headers="headers"
              :items="datas"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:item.nomor="{ item }">
                {{ datas.indexOf(item) + 1 }}
              </template>
              <!-- <template v-slot:item.akreditas="{ item }">
              <v-chip color="success" label>{{ item.akreditas }}</v-chip>
            </template> -->
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  color="teal darken-2"
                  x-small
                  class="ma-2 white--text"
                  @click="riwayat(item)"
                >
                  Riwayat
                  <v-icon right dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  :label="$t('tabel.cari')"
                  class="mx-4 pt-4"
                ></v-text-field
              ></template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyCard from "@/components/MyCard.vue";
export default {
  name: "Akreditas",
  components: {
    MyCard,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    valid: true,
    rules: [(v) => !!v || "Form ini wajib di isi"],
    filterTahun: localStorage.filterTahun || "",
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Program Studi", value: "program_studi" },
      { text: "Jenjang", value: "jenjang" },
      { text: "Akreditasi", value: "akreditas" },
      { text: "Aksi", value: "aksi" },
    ],
    datas: [],
    count: [],
    tahunItems: [
      { id: "2023", name: "2023" },
      { id: "2022", name: "2022" },
      { id: "2021", name: "2021" },
      { id: "2020", name: "2020" },
      { id: "2019", name: "2019" },
      { id: "2018", name: "2018" },
      { id: "2017", name: "2017" },
      { id: "2016", name: "2016" },
      { id: "2015", name: "2015" },
    ],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getData(this.filterTahun);
      this.getCount(this.filterTahun);
    },

    async getData($tahun) {
      try {
        if ($tahun == "") {
          const response = await this.$axios.get("/api/listAkreditasLatest");
          this.datas = response.data.data;
        } else {
          const response = await this.$axios.get(
            "/api/listAkreditasLatest/" + $tahun
          );
          this.datas = response.data.data;
        }
        // console.log(this.datas);
      } catch (error) {
        console.log(error);
      }
    },

    async getCount($tahun) {
      try {
        if ($tahun == "") {
          const response = await this.$axios.get("/api/countAkreditasByStatus");
          this.count = response.data;
        } else {
          const response = await this.$axios.get(
            "/api/countAkreditasByStatus/" + $tahun
          );
          this.count = response.data;
        }
        // console.log(this.count);
      } catch (error) {
        console.log(error);
      }
    },

    riwayat(item) {
      // console.log(item);
      this.$router.push(
        "/id/akreditas/" + item.program_studi + "/jenjang/" + item.jenjang
      );
    },

    loadData() {
      console.log("clicked");
    },

    tahunChange: function(e) {
      // console.log(e);
      localStorage.filterTahun = e;
      this.initialize();
    },

    submit() {
      if (this.$refs.form.validate()) {
        console.log(this.filterTahun);
        // save to local storage
        localStorage.filterTahun = this.filterTahun;
        this.initialize();
        // this.$vloading.show();
        // let config = {
        //   headers: {},
        // };
        // let formData = new FormData();
        // formData.set("NPSN", this.filterSekolah);
        // formData.set("ADMISSIONID", this.filterJalur);
        // this.axios
        //   .post("/api/resultAccepted", formData, config)
        //   .then((response) => {
        //     this.admission = response.data.data;
        //     // this.status = response.data.dataStatus;
        //     this.$vloading.hide();
        //     // console.log(this.admission);
        //     // console.log(this.status);
        //   })
        //   .catch((error) => {
        //     console.log(error.response.data);
        //     this.$vloading.hide();
        //     this.admission = [];
        //     this.status = [];
        //     this.setAlert({
        //       status: true,
        //       color: "error",
        //       text: error.response.data.message,
        //     });
        //     this.close();
        //   });
      }
      // this.closes();
    },

    resetFilterTahun() {
      localStorage.filterTahun = "";
      this.filterTahun = "";
      this.initialize();
    },
  },
};
</script>
