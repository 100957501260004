<template>
  <div>
    <v-card
      :color="color"
      dark
      class="text-center"
      elevation="3"
      :to="url"
      @click="method"
    >
      <v-card-title
        class="text-md-h4 text-h5 font-weight-bold justify-center"
        :style="'color: ' + text_color"
      >
        {{ title }}
      </v-card-title>
      <v-card-actions
        :style="'background-color:' + bottom_color"
        class="justify-center text-caption text-md-body-2"
      >
        <v-icon left dark>{{ icon }}</v-icon>
        {{ actions }}
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "MyCard",
  props: [
    "color",
    "title",
    "bottom_color",
    "actions",
    "icon",
    "text_color",
    "url",
    "method",
  ],
};
</script>
