var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mx-4 mt-4",attrs:{"cols":"12"}},[_c('div',[_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("kementerian.layanan")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("kementerian.sister")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Penelitian Dan Publikasi ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('span',{staticStyle:{"color":"#939292"}},[_vm._v("Detail")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.datas.nama)+" ("+_vm._s(_vm.datas.nidn)+")")]),_c('v-divider',{staticStyle:{"border-top":"5px solid #990000"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"focusable":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"background":"#990000","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Penelitian ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"loading":_vm.isLoading,"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersPenelitian,"items":_vm.penelitian,"search":_vm.searchPenelitian,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.penelitian.indexOf(item) + 1)+" ")]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"teal darken-2","x-small":""},on:{"click":function($event){return _vm.riwayat(item)}}},[_vm._v(" Detail "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 pt-4",attrs:{"label":_vm.$t('tabel.cari')},model:{value:(_vm.searchPenelitian),callback:function ($$v) {_vm.searchPenelitian=$$v},expression:"searchPenelitian"}})]},proxy:true}])})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"background":"#990000","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Publikasi ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"loading":_vm.isLoading,"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersPublikasi,"items":_vm.publikasi,"search":_vm.searchPublikasi,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.publikasi.indexOf(item) + 1)+" ")]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"teal darken-2","x-small":""},on:{"click":function($event){return _vm.riwayat(item)}}},[_vm._v(" Detail "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 pt-4",attrs:{"label":_vm.$t('tabel.cari')},model:{value:(_vm.searchPublikasi),callback:function ($$v) {_vm.searchPublikasi=$$v},expression:"searchPublikasi"}})]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }