<template>
  <div>
    <a @click="$router.back()" style="text-decoration: none">{{ $t(parent) }}</a
    ><v-icon small>mdi-chevron-right</v-icon
    ><span style="color: #939292">{{ $t(sub_parent) }}</span>
  </div>
</template>
<script>
export default {
  name: "MyBreadcrumbs",
  props: ["parent", "sub_parent", "url1"],
};
</script>