<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <span class="hidden-xs-only"> <br /><br /><br /><br /></span>
          <span class="text-h5"
            ><strong>{{ $t("selamat_datang_di") }}</strong></span
          >
          <br /><br />
          <span
            class="font-weight-black text--accent-1"
            style="color: #03a9f4; font-size: 55pt; line-height: 1em"
            >{{ appName }}</span
          >
          <br />
          <span class="text-h5 text-md-h4 font-weight-light ml-1">{{
            appNameUNG
          }}</span
          ><br /><br />
          <span class="text-body-1">UPT Teknologi Informasi dan Komunikasi</span
          ><br /><br />
          <v-alert
            border="left"
            colored-border
            color="primary"
            style="background-color: #edecec"
            dense
          >
            <i>infografis.ung.ac.id</i> {{ $t("deskripsi") }}.
          </v-alert>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="hidden-xs-only"> <br /><br /><br /></span>
          <center>
            <carousel
              :per-page="1"
              navigationEnabled
              paginationPosition="bottom-overlay"
              autoplay
              loop
            >
              <slide>
                <img src="/img/logo/infografis.png" width="80%" />
              </slide>
              <!-- <slide>
                <img src="/img/image/slide1.jpg" style="width: 100%" />
              </slide>
              <slide>
                <img src="/img/image/slide2.jpg" style="width: 100%" />
              </slide>
              <slide>
                <img src="/img/image/slide3.jpg" style="width: 100%" />
              </slide>
              <slide>
                <img src="/img/image/slide4.jpg" style="width: 100%" />
              </slide>
              <slide>
                <img src="/img/image/slide5.jpg" style="width: 100%" />
              </slide> -->
            </carousel>
          </center>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br /><br /><br />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Visi Dan Misi</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12">
          <h3>A. Visi</h3>
          <p>
            Menjadi Universitas Unggul dan Berdaya Saing dalam Pengembangan
            Inovasi Kawasan
          </p>
        </v-col>
        <v-col cols="12">
          <h3>B. Misi</h3>
          <ol>
            <li>
              Meningkatkan kualitas penyelenggaraan pendidikan dan pembelajaran
              dalam pengembangan kependidikan dan kemaritiman menggunakan
              teknologi terkini untuk menghasilkan lulusan yang profesional,
              kreatif dan inovatif;
            </li>
            <li>
              Meningkatkan kualitas penyelenggaraan penelitian dalam bidang
              kependidikan dan kemaritiman untuk pengembangan ilmu pengetahuan
              dan teknologi yang bermanfaat bagi pembangunan daerah dan
              nasional, serta meningkatkan kesejahteraan dan kemandirian
              masyarakat;
            </li>
            <li>
              Meningkatkan kualitas penyelenggaraan kegiatan pengabdian kepada
              masyarakat dalam bidang kependidikan dan kemaritiman yang kreatif
              dan inovatif berdasarkan nilai-nilai gotong royong dan kebhinekaan
              global;
            </li>
            <li>
              Meningkatkan kuantitas dan kualitas kerjasama nasional dan
              internasional dalam bidang kependidikan dan kemaritiman; dan
            </li>
            <li>
              Meningkatkan kualitas penyelenggaraan tata kelola yang efektif,
              efisien, transparan dan akuntabel secara konsisten dan
              berkelanjutan.
            </li>
          </ol>
        </v-col>
        <v-col cols="12">
          <h3>C. Tujuan</h3>
          <ol>
            <li>
              Meningkatnya kualitas lulusan yang berakhlak mulia, profesional,
              kreatif, dan inovatif dalam bidang kependidikan dan kemaritiman
            </li>
            <li>
              Meningkatnya kuantitas dan kualitas luaran penelitian dalam bidang
              kependidikan dan kemaritiman yang bermanfaat dalam percepatan
              pembangunan di Kawasan Timur Indonesia
            </li>
            <li>
              Meningkatnya kuantitas dan kualitas luaran pengabdian kepada
              masyarakat dalam bidang kependidikan dan kemaritiman yang kreatif
              dan inovatif
            </li>
            <li>
              Meningkatnya kuantitas dan kualitas kerjasama nasional dan
              internasional dalam bidang kependidikan dan kemaritiman Kawasan
              Timur Indonesia
            </li>
            <li>
              Meningkatnya kualitas kinerja satuan kerja yang efektif dan
              efisien melalui tata kelola yang baik, terintegrasi, transparan
              dan akuntabel
            </li>
          </ol>
        </v-col>
        <v-col cols="12">
          <h3>D. Sasaran</h3>
          <ol>
            <li>
              Terwujudnya mahasiswa dan lulusan yang berkualitas
            </li>
            <li>
              Terbentuknya sistem pendidikan yang berkualitas
            </li>
            <li>
              Terbentuknya SDM dosen yang kompeten, unggul dan berdaya saing
            </li>
            <li>
              Terciptanya luaran penelitian yang berkualitas, mendapat
              rekognisi, dan diterapkan di masyarakat
            </li>
            <li>
              Terciptanya luaran pengabdian yang diterapkan di masyarakat
            </li>
            <li>
              Terwujudnya kerjasama untuk meningkatkan kapasitas organisasi
            </li>
            <li>
              Terbentuknya sistem perencanaan dan keuangan yang akuntabel dan
              transparan
            </li>
            <li>
              Terwujudnya organisasi yang efektif, efisien, dan berorientasi
              pada layanan prima
            </li>
            <li>
              Terwujudnya sistem informasi terintegrasi dalam platform tunggal
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container>
      <v-row>
        <v-col cols="12" md="5">
          <v-img src="/img/logo/animasi-dash.gif" style="margin-top: -50px" />
        </v-col>
        <v-col cols="12" md="7">
          <h2>VISI</h2>
          <v-divider
            style="width: 10%; border-top: 5px solid #990000"
          ></v-divider
          ><br />
          <span class="text-body-2 text-md-h6">UNGGUL DAN BERDAYA SAING</span
          ><br /><br />
          <h2>MISI</h2>
          <v-divider
            style="width: 10%; border-top: 5px solid #990000"
          ></v-divider
          ><br />
          <span class="text-caption text-md-subtitle-1 text-justify">
            <ol>
              <li>
                Mengembangkan pendidikan dan pengajaran digital based learning
                guna menghasilkan lulusan yang memiliki kemampuan
                profesionalitas dengan menguasai teknologi di Bidang
                Kependidikan atau Non Kependidikan
              </li>
              <li>
                Meningkatkan kompetensi penelitian dan mengembangkan kompetensi
                untuk menghasilkan ilmu pengetahuan dan teknologi terbarukan
                yang memiliki manfaat untuk meningkatkan kesejahteraan
                masyarakat, pembangunan daerah, dan nasional secara
                berkelanjutan
              </li>
              <li>
                Mengembangkan kegiatan pengabdian kepada masyarakat yang
                inovatif, melakukan penyebarluasan serta penerapan hasil-hasil
                penelitian untuk menunjang pembangunan daerah dan nasional dalam
                rangka mengurangi angka kemiskinan dan ketimpangan
              </li>
              <li>
                Mengembangkan kemitraan dan jejaring kerjasama yang luas untuk
                memajukan pendidikan, penelitian dan pengabdian kepada
                masyarakat
              </li>
              <li>
                Memperkuat nilai-nilai budaya, penerapan sains dan teknologi
                serta inovasi berbasis potensi regional
              </li>
              <li>
                Menyelenggarakan tata kelola dan layanan yang profesional,
                transparan dan akuntabilitas yang tinggi menuju good university
                governance
              </li>
            </ol>
          </span>
        </v-col>
      </v-row> 
    </v-container> -->
    <br />
    <!-- JUMLAH MAHASISWA -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("akademik.jumlah") }}</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
          v-for="dt in itemsFakultas"
          :key="dt.fakultas"
        >
          <span v-if="dt.kode_fakultas == '07'">
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              :actions="dt.fakultas"
              icon="mdi-account-group"
              text_color="white"
              :url="
                `/${$i18n.locale}` + '/jumlah-mahasiswa/' + dt.kode_fakultas
              "
            />
          </span>
          <span
            v-else-if="dt.kode_fakultas == '08' || dt.kode_fakultas == '09'"
          >
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              :actions="dt.fakultas.substring(9)"
              icon="mdi-account-group"
              text_color="#1b1b1b"
              :url="
                `/${$i18n.locale}` + '/jumlah-mahasiswa/' + dt.kode_fakultas
              "
            />
          </span>
          <span v-else-if="dt.kode_fakultas == '02'">
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              actions="MATEMATIKA DAN IPA"
              icon="mdi-account-group"
              text_color="white"
              :url="
                `/${$i18n.locale}` + '/jumlah-mahasiswa/' + dt.kode_fakultas
              "
            />
          </span>
          <span v-else>
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              :actions="dt.fakultas.substring(9)"
              icon="mdi-account-group"
              text_color="white"
              :url="
                `/${$i18n.locale}` + '/jumlah-mahasiswa/' + dt.kode_fakultas
              "
            />
          </span>
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              {{ $t("akademik.grafik_mhs_pertahun").toUpperCase() }}
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12" md="8" lg="8">
                    <div id="chart">
                      <apexchart
                        type="area"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4">
                    <v-data-table
                      :headers="headers"
                      :items="jumlahMahasiswa"
                      :items-per-page="5"
                      mobile-breakpoint="0"
                      sort-by="angkatan"
                      sort-desc=""
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                      }"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-container> </v-card-title
          ></v-card>
        </v-col>
      </v-row> </v-container
    ><br />
    <!-- STATUS MAHASISWA -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("akademik.status") }}</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            :actions="$t('akademik.mhs_aktif')"
            bottom_color="#388E3C"
            color="green"
            icon="mdi-account-check"
            :title="mahasiswaAktif.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            :actions="$t('akademik.mhs_nonaktif')"
            bottom_color="#D32F2F"
            color="red"
            icon="mdi-account-remove"
            :title="mahasiswaTidakAktif.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            :actions="$t('akademik.mhs_lulus')"
            bottom_color="#0288D1"
            color="light-blue"
            icon="mdi-account-school"
            :title="mahasiswaLulus.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            :actions="$t('akademik.mhs_baru') + tahun"
            bottom_color="#F57C00"
            color="orange"
            icon="mdi-account-plus"
            :title="mahasiswaBaru.toLocaleString('en-US')"
          />
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              {{ $t("akademik.mhs_aktif").toUpperCase() }}
            </v-card-actions>
            <v-card-title
              class="text-body-2 text-md-body-1 font-weight-bold justify-center"
              style="word-break: break-word"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="4" lg="4">
                    {{ $t("akademik.mhs_aktif_jenjang") }}
                    <div id="chart3">
                      <apexchart
                        type="donut"
                        :options="chartOptions3"
                        :series="series3"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4">
                    {{ $t("akademik.mhs_aktif_angkatan") }}<br /><br />
                    <div id="chart2">
                      <apexchart
                        type="bar"
                        :options="chartOptions2"
                        :series="series2"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4">
                    {{ $t("akademik.mhs_aktif_jenkel") }}<br /><br />
                    <div id="chart9">
                      <apexchart
                        type="pie"
                        :options="chartOptions9"
                        :series="series9"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              {{ $t("akademik.status_regis").toUpperCase() }} {{ tahun }}
            </v-card-actions>
            <v-card-title
              class="text-body-2 text-md-body-1 font-weight-bold justify-center"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    lg="3"
                    v-for="dt in statusRegistrasi"
                    :key="dt.jenjang"
                  >
                    <MyCard
                      color="indigo"
                      :actions="dt.jenjang + ' ' + $t('akademik.diterima')"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="dt.count"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row> </v-container
    ><br />
    <!-- SEBARAN MAHASISWA -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("akademik.sebaran") }}</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              {{ $t("akademik.peta_sebaran").toUpperCase() }}
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1 text-left">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="hello" ref="chartdiv" id="chartdiv"></div>
                    <br />
                    <small
                      ><em
                        >*{{ $t("akademik.belum_terdata") }} :
                        {{ belumLengkap.toLocaleString("en-US") }}</em
                      ></small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-left" elevation="3">
            <v-card-title class="text-body-2 text-md-body-1 font-weight-bold">
              <v-container>
                <v-row>
                  <v-col cols="12" md="7" lg="7">
                    Gorontalo :
                    {{ mhsGorontalo.toLocaleString("en-US") }}
                    {{ $t("akademik.mhs") }}<br /><br />
                    <div id="chart5">
                      <apexchart
                        type="bar"
                        height="320"
                        :options="chartOptions5"
                        :series="series5"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" lg="5">
                    {{ $t("akademik.perbandingan") }}
                    <div id="chart4">
                      <apexchart
                        type="pie"
                        :options="chartOptions4"
                        :series="series4"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-left" elevation="3">
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <strong>{{ $t("akademik.detail_sebaran") }}</strong
                    ><br />
                    <v-data-table
                      :headers="headers2"
                      :items="jumlahMahasiswa2"
                      :search="search"
                      mobile-breakpoint="0"
                    >
                      <template v-slot:item.nomor="{ item }">
                        {{ jumlahMahasiswa2.indexOf(item) + 1 }}
                      </template>
                      <template v-slot:item.kabupaten="{ item }">
                        <span
                          v-if="item.kabupaten == ''"
                          class="text-caption text-md-body-2"
                          style="word-break: break-word"
                          >BELUM TERDATA</span
                        >
                        <span
                          v-else
                          class="text-caption text-md-body-2"
                          style="word-break: break-word"
                          >{{ item.kabupaten }}</span
                        >
                      </template>
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          :label="$t('tabel.cari')"
                          class="mx-4 pt-4"
                        ></v-text-field
                      ></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row> </v-container
    ><br />
    <!-- LULUSAN MAHASISWA -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("akademik.lulusan") }}</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>

        <v-col cols="12" md="3" lg="3">
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan.toLocaleString("en-US") }}
            </v-card-title>
            <v-card-actions
              style="background-color: white"
              class="justify-center text-caption text-md-body-2 pt-5"
            >
              <div id="chart6">
                <apexchart
                  type="pie"
                  width="320"
                  :options="chartOptions6"
                  :series="series6"
                ></apexchart>
              </div>
            </v-card-actions> </v-card
          ><br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan Tahun {{ tahun }}
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan1Tahun.toLocaleString("en-US") }}
            </v-card-title> </v-card
          ><br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Total Lulusan 5 Tahun Terakhir
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              {{ totalLulusan5Tahun.toLocaleString("en-US") }}
            </v-card-title>
          </v-card>
          <!-- <br />
          <v-card color="indigo" dark class="text-center" elevation="3">
            <v-card-actions class="justify-center text-caption text-md-body-2">
              <v-icon left dark>mdi-account-school</v-icon>
              Lulusan Cum Laude 5 Tahun Terakhir
            </v-card-actions>
            <v-card-title
              class="text-md-h4 text-h5 font-weight-bold justify-center"
            >
              4.330
            </v-card-title>
          </v-card> -->
        </v-col>

        <v-col cols="12" md="9" lg="9">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              TOTAL LULUSAN PERTAHUN
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div id="chart7">
                      <apexchart
                        type="line"
                        height="300"
                        :options="chartOptions7"
                        :series="series7"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title> </v-card
          ><br />
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #00bcd4"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              TOTAL LULUSAN FAKULTAS
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div id="chart8">
                      <apexchart
                        type="bar"
                        height="300"
                        :options="chartOptions8"
                        :series="series8"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_indonesiaHigh from "@amcharts/amcharts5-geodata/indonesiaHigh";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated.js";

import MyCard from "../components/MyCard.vue";

export default {
  name: "Home",
  components: {
    MyCard,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    itemsFakultas: [],

    series: [],
    chartOptions: {},
    series2: [],
    chartOptions2: [],
    series3: [],
    chartOptions3: [],
    series4: [],
    chartOptions4: [],
    series5: [],
    chartOptions5: [],
    series6: [],
    chartOptions6: [],
    series7: [],
    chartOptions7: [],
    series8: [],
    chartOptions8: [],
    series9: [],
    chartOptions9: [],

    headers: [
      { text: "Tahun", value: "angkatan" },
      { text: "Jumlah Mahasiswa", value: "count" },
    ],

    jumlahMahasiswa: [],

    mahasiswaAktif: 0,
    mahasiswaTidakAktif: 0,
    mahasiswaLulus: 0,
    mahasiswaBaru: 0,
    statusRegistrasi: [],

    belumLengkap: 0,
    mhsGorontalo: 0,
    search: "",
    headers2: [
      { text: "No", value: "nomor" },
      { text: "Nama Kota / Kab", value: "kabupaten" },
      { text: "Jumlah Mahasiswa", value: "count" },
    ],
    jumlahMahasiswa2: [],

    totalLulusan: 0,
    totalLulusan1Tahun: 0,
    totalLulusan5Tahun: 0,
  }),
  computed: {},
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaAktifByFakultas();
      this.countMahasiswaByTahun();
      this.countMahasiswaAktif();
      this.countMahasiswaNonAktif();
      this.countMahasiswaLulus();
      this.countMahasiswaBaru();
      this.countMahasiswaAktifByAngkatan();
      this.countMahasiswaByJenisKelamin();
      this.countMahasiswaAktifByJenjang();
      this.countMahasiswaAktifByStatusRegistrasi();
      this.countMahasiswaAktifByProvinsi();
      this.countMahasiswaAktifByPerbandinganDomisili();
      this.countMahasiswaAktifByGorontalo();
      this.countMahasiswaAktifByKabupaten();
      this.countMahasiswaLulusan();
      this.countMahasiswaLulusByJenjang();
      this.countMahasiswaLulusByTahun();
      this.countMahasiswaLulusByFakultas();
    },

    countMahasiswaAktifByFakultas() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByFakultas")
        .then((response) => {
          this.$vloading.hide();
          this.itemsFakultas = response.data.data;
          // console.log(response);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaByTahun() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaByTahun")
        .then((response) => {
          this.$vloading.hide();
          this.jumlahMahasiswa = response.data.data;
          // console.log(response);

          var data = response.data.data;

          const count = data.map((key) => {
            return key.count;
          });

          const angkatan = data.map((key) => {
            return key.angkatan;
          });

          this.series = [
            {
              name: "Jumlah Mahasiswa",
              data: count,
            },
          ];
          this.chartOptions = {
            chart: {
              type: "area",
              height: 350,
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },

            title: {
              text: "1995 - " + this.tahun,
              align: "center",
            },
            labels: angkatan,
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktif() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktif")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaAktif = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaNonAktif() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaNonAktif")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaTidakAktif = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaLulus() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulus")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaLulus = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaBaru() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaBaru")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaBaru = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktifByAngkatan() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByAngkatan")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.data;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const angkatan = data.map((key) => {
            return key.angkatan;
          });

          this.series2 = [
            {
              name: "Mahasiswa Aktif",
              data: count,
            },
          ];
          this.chartOptions2 = {
            chart2: {
              type: "bar",
            },
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: angkatan,
              labels: {
                style: {
                  colors: [
                    "#4CAF50",
                    "#FF9800",
                    "#F44336",
                    "#2196F3",
                    "#9C27B0",
                    "#795548",
                    "#E91E63",
                    "#607D8B",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series2 = [];
          this.chartOptions2 = [];
        });
    },

    countMahasiswaByJenisKelamin() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaByJenisKelamin")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const sex = data.map((key) => {
            return key.sex;
          });

          this.series9 = count;
          this.chartOptions9 = {
            chart9: {
              type: "pie",
            },
            legend: {
              position: "bottom",
            },
            colors: ["#2196F3", "#F44336"],
            labels: sex,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series2 = [];
          this.chartOptions2 = [];
        });
    },

    countMahasiswaAktifByJenjang() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByJenjang")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const jenjang = data.map((key) => {
            return key.jenjang;
          });

          this.series3 = count;
          this.chartOptions3 = {
            chart3: {
              type: "donut",
            },
            labels: jenjang,
            legend: {
              position: "bottom",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series3 = [];
          this.chartOptions3 = [];
        });
    },

    countMahasiswaAktifByStatusRegistrasi() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByStatusRegistrasi/" + this.tahun)
        .then((response) => {
          this.$vloading.hide();
          this.statusRegistrasi = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktifByProvinsi() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByProvinsi")
        .then((response) => {
          this.$vloading.hide();
          var labelData = response.data;

          var bL = labelData.filter(function(item) {
            return item.polygonId == null;
          });
          this.belumLengkap = bL[0].name;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktifByPerbandinganDomisili() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByPerbandinganDomisili")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;
          // console.log(response.data.data);

          this.mhsGorontalo = data.count_gorontalo;

          this.series4 = [data.count_gorontalo, data.count_lainnya];
          this.chartOptions4 = {
            chart4: {
              type: "pie",
            },
            legend: {
              position: "bottom",
            },
            colors: ["#F44336", "#2196F3"],
            labels: ["Gorontalo", "Luar Gorontalo"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series4 = [];
          this.chartOptions4 = [];
        });
    },

    countMahasiswaAktifByGorontalo() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByGorontalo")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const kabupaten = data.map((key) => {
            return key.kabupaten;
          });

          this.series5 = [
            {
              name: "Jumlah Mahasiswa",
              data: count,
            },
          ];
          this.chartOptions5 = {
            chart5: {
              type: "bar",
            },
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
            xaxis: {
              categories: kabupaten,
              labels: {
                show: false,
                style: {
                  colors: [
                    "#4CAF50",
                    "#FF9800",
                    "#F44336",
                    "#2196F3",
                    "#9C27B0",
                    "#795548",
                    "#E91E63",
                    "#607D8B",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series5 = [];
          this.chartOptions5 = [];
        });
    },

    countMahasiswaAktifByKabupaten() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByKabupaten")
        .then((response) => {
          this.$vloading.hide();
          this.jumlahMahasiswa2 = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaLulusan() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulus")
        .then((response) => {
          this.$vloading.hide();
          this.totalLulusan = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaLulusByJenjang() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByJenjang")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;

          const count = data.map((key) => {
            return key.count;
          });

          const jenjang = data.map((key) => {
            return key.jenjang;
          });

          this.series6 = count;
          this.chartOptions6 = {
            chart6: {
              type: "pie",
            },
            colors: [
              "#4CAF50",
              "#F44336",
              "#FF9800",
              "#9C27B0",
              "#2196F3",
              "#607D8B",
              "#E91E63",
              "#795548",
            ],
            legend: {
              position: "top",
            },
            dataLabels: {
              enabled: true,
            },
            labels: jenjang,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "top",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series6 = [];
          this.chartOptions6 = [];
        });
    },

    countMahasiswaLulusByTahun() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByTahun")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data;

          const count = data.map((key) => {
            return key.count;
          });

          const tahun = data.map((key) => {
            return key.tahun;
          });

          this.series7 = [
            {
              name: "Total Lulusan",
              data: count,
            },
          ];
          this.chartOptions7 = {
            chart7: {
              height: 300,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            markers: {
              size: 5,
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: tahun,
            },
          };

          this.totalLulusan1Tahun = data.pop().count;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series7 = [];
          this.chartOptions7 = [];
        });
    },

    countMahasiswaLulusByFakultas() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulusByFakultas")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const fakultas = data.map((key) => {
            return key.fakultas;
          });

          this.series8 = [
            {
              name: "Total Lulusan",
              data: count,
            },
          ];
          this.chartOptions8 = {
            chart8: {
              height: 350,
              type: "bar",
            },
            colors: [
              "#D32F2F",
              "#C2185B",
              "#7B1FA2",
              "#0288D1",
              "#512DA8",
              "#0097A7",
              "#00796B",
              "#388E3C",
              "#AFB42B",
              "#f57c00",
              "#5D4037",
              "#455A64",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
            xaxis: {
              categories: fakultas,
              labels: {
                show: false,
                style: {
                  colors: [
                    "#D32F2F",
                    "#C2185B",
                    "#7B1FA2",
                    "#0288D1",
                    "#512DA8",
                    "#0097A7",
                    "#00796B",
                    "#388E3C",
                    "#AFB42B",
                    "#f57c00",
                    "#5D4037",
                    "#455A64",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series8 = [];
          this.chartOptions8 = [];
        });
    },
  },

  mounted() {
    // Create root and chart
    var root = am5.Root.new("chartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoNaturalEarth1(),
      })
    );

    // Create polygon series
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_indonesiaHigh,
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color("#82C2E0"),
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color("#ffc107"),
    });

    // Create series for labels
    var pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        polygonIdField: "polygonId",
      })
    );

    pointSeries.bullets.push(function() {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          fontSize: 10,
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{name}",
          populateText: true,
        }),
      });
    });

    // Add state labels
    polygonSeries.events.on("datavalidated", function() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/countMahasiswaAktifByProvinsi")
        .then((response) => {
          var labelData = response.data;
          // console.log(response.data);
          pointSeries.data.setAll(labelData);
        })
        .catch((error) => {
          console.log(error);
          var labelData = [];
          // console.log(response);
          pointSeries.data.setAll(labelData);
        });
    });
  },

  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>
