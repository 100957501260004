<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>
            {{ $t("akreditas.akreditas_prodi") }} {{ $route.params.jenjang }}
            {{ $route.params.id }}
          </h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="datas"
            :search="search"
            mobile-breakpoint="0"
          >
            <template v-slot:item.nomor="{ item }">
              {{ datas.indexOf(item) + 1 }}
            </template>
            <!-- <template v-slot:item.akreditas="{ item }">
              <v-chip color="success" label>{{ item.akreditas }}</v-chip>
            </template> -->
            <template v-slot:item.aksi>
              <v-btn color="secondary" x-small class="ma-2 white--text">
                Riwayat
                <v-icon right dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="search"
                :label="$t('tabel.cari')"
                class="mx-4 pt-4"
              ></v-text-field
            ></template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AkreditasById",
  components: {},
  data: () => ({
    tahun: new Date().getFullYear(),
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Program Studi", value: "program_studi" },
      { text: "Jenjang", value: "jenjang" },
      { text: "Akreditasi", value: "akreditas" },
      { text: "Tahun", value: "tahun" },
    ],
    datas: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getData();
    },

    async getData() {
      try {
        const response = await this.$axios.get(
          "/api/listAkreditasByProdi/" +
            this.$route.params.id +
            "/" +
            this.$route.params.jenjang
        );
        this.datas = response.data.data;
        // console.log(this.datas);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
