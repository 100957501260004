<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs parent="akademik.data" sub_parent="akademik.status" />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            actions="Mahasiswa Aktif"
            bottom_color="#388E3C"
            color="green"
            icon="mdi-account-check"
            :title="mahasiswaAktif.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            actions="Mahasiswa Tidak Aktif"
            bottom_color="#D32F2F"
            color="red"
            icon="mdi-account-remove"
            :title="mahasiswaTidakAktif.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            actions="Mahasiswa Lulus"
            bottom_color="#0097A7"
            color="cyan"
            icon="mdi-account-school"
            :title="mahasiswaLulus.toLocaleString('en-US')"
          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <MyCard
            :actions="'Mahasiswa Baru T.A ' + tahun"
            bottom_color="#F57C00"
            color="orange"
            icon="mdi-account-plus"
            :title="mahasiswaBaru.toLocaleString('en-US')"
          />
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              MAHASISWA AKTIF
            </v-card-actions>
            <v-card-title
              class="text-body-2 text-md-body-1 font-weight-bold justify-center"
              style="word-break: break-word"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    Mahasiswa Aktif : Berdasarkan Jenjang Pendidikan
                    <div id="chart">
                      <apexchart
                        type="donut"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    Mahasiswa Aktif : Berdasarkan Angkatan<br /><br />
                    <div id="chart2">
                      <apexchart
                        type="bar"
                        :options="chartOptions2"
                        :series="series2"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              STATUS REGISTRASI T.A. 2022
            </v-card-actions>
            <v-card-title
              class="text-body-2 text-md-body-1 font-weight-bold justify-center"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    lg="3"
                    v-for="dt in statusRegistrasi"
                    :key="dt.jenjang"
                  >
                    <MyCard
                      color="indigo"
                      :actions="dt.jenjang + ' Diterima'"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="dt.count"
                    />
                  </v-col>
                  <!-- <v-col cols="12" md="2" lg="2">
                    <MyCard
                      color="indigo"
                      actions="S1 Diterima"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="diterimaS1"
                    />
                  </v-col>
                  <v-col cols="12" md="2" lg="2">
                    <MyCard
                      color="indigo"
                      actions="S2 Diterima"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="diterimaS2"
                    />
                  </v-col>
                  <v-col cols="12" md="2" lg="2">
                    <MyCard
                      color="indigo"
                      actions="S3 Diterima"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="diterimaS3"
                    />
                  </v-col>
                  <v-col cols="12" md="2" lg="2">
                    <MyCard
                      color="indigo"
                      actions="Sp Diterima"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="diterimaSp"
                    />
                  </v-col>
                  <v-col cols="12" md="2" lg="2">
                    <MyCard
                      color="indigo"
                      actions="Profesi Diterima"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      :title="diterimaProfesi"
                    />
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <!-- <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              IPK MAHASISWA AKTIF
            </v-card-actions>
            <v-card-title
              class="text-body-2 text-md-body-1 font-weight-bold justify-center"
            >
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div id="chart3">
                      <apexchart
                        type="bar"
                        height="400"
                        :options="chartOptions3"
                        :series="series3"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyCard from "../../components/MyCard.vue";
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";
export default {
  name: "StatusMahasiswa",
  components: {
    MyCard,
    MyBreadcrumbs,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    mahasiswaAktif: 0,
    mahasiswaTidakAktif: 0,
    mahasiswaLulus: 0,
    mahasiswaBaru: 0,

    statusRegistrasi: [],

    series: [],
    chartOptions: [],

    series2: [],
    chartOptions2: [],

    series3: [
      {
        data: [13144, 5236, 1713, 625, 497, 7440],
      },
    ],
    chartOptions3: {
      chart3: {
        type: "bar",
      },
      colors: [
        "#4CAF50",
        "#FF9800",
        "#F44336",
        "#2196F3",
        "#9C27B0",
        "#795548",
        "#E91E63",
        "#607D8B",
      ],
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          ["4.0 - 3.5"],
          ["3.5 - 3.0"],
          ["3.0 - 2.5"],
          ["2.5 - 2.0"],
          ["2.0 - 1.0"],
          ["1.0 - 0"],
        ],
        labels: {
          style: {
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            fontSize: "12px",
          },
        },
      },
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaAktif();
      this.countMahasiswaNonAktif();
      this.countMahasiswaLulus();
      this.countMahasiswaBaru();
      this.countMahasiswaAktifByAngkatan();
      this.countMahasiswaAktifByJenjang();
      this.countMahasiswaAktifByStatusRegistrasi();
    },

    countMahasiswaAktif() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktif")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaAktif = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaNonAktif() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaNonAktif")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaTidakAktif = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaLulus() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaLulus")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaLulus = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaBaru() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaBaru")
        .then((response) => {
          this.$vloading.hide();
          this.mahasiswaBaru = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaAktifByAngkatan() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByAngkatan")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.data;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const angkatan = data.map((key) => {
            return key.angkatan;
          });

          this.series2 = [
            {
              name: "Mahasiswa Aktif",
              data: count,
            },
          ];
          this.chartOptions2 = {
            chart2: {
              type: "bar",
            },
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: angkatan,
              labels: {
                style: {
                  colors: [
                    "#4CAF50",
                    "#FF9800",
                    "#F44336",
                    "#2196F3",
                    "#9C27B0",
                    "#795548",
                    "#E91E63",
                    "#607D8B",
                  ],
                  fontSize: "12px",
                },
              },
            },
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series2 = [];
          this.chartOptions2 = [];
        });
    },

    countMahasiswaAktifByJenjang() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByJenjang")
        .then((response) => {
          this.$vloading.hide();
          var data = response.data.count;
          // console.log(response.data.data);

          const count = data.map((key) => {
            return key.count;
          });

          const jenjang = data.map((key) => {
            return key.jenjang;
          });

          this.series = count;
          this.chartOptions = {
            chart: {
              type: "donut",
            },
            labels: jenjang,
            legend: {
              position: "bottom",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.series = [];
          this.chartOptions = [];
        });
    },

    countMahasiswaAktifByStatusRegistrasi() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByStatusRegistrasi/" + this.tahun)
        .then((response) => {
          this.$vloading.hide();
          this.statusRegistrasi = response.data.count;
          // console.log(response.data.count);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },
  },
};
</script>