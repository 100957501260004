<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="mx-4 mt-4">
          <div>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.layanan")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.sister")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <a @click="$router.back()" style="text-decoration: none"
              >Pengabdian pada Masyarakat
            </a>
            <v-icon small>mdi-chevron-right</v-icon>
            <span style="color: #939292">Detail</span>
          </div>
        </v-col>

        <v-col cols="12">
          <h2>{{ datas.nama }} ({{ datas.nidn }})</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
          <v-card :loading="isLoading" flat>
            <v-data-table
              :headers="headers"
              :items="pengabdian"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:item.nomor="{ item }">
                {{ pengabdian.indexOf(item) + 1 }}
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  color="teal darken-2"
                  x-small
                  class="ma-2 white--text"
                  @click="riwayat(item)"
                >
                  Detail
                  <v-icon right dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  :label="$t('tabel.cari')"
                  class="mx-4 pt-4"
                ></v-text-field
              ></template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </div>
</template>

<script>
export default {
  name: "Sertifikasi_dosen",
  components: {},
  data: () => ({
    isLoading: false,
    panel: [0, 1],
    tahun: new Date().getFullYear(),
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Judul", value: "judul" },
      { text: "Lama Kegiatan", value: "lama_kegiatan" },
      {
        text: "Tahun Pelaksanaan",
        value: "tahun_pelaksanaan",
      },
      // { text: "Bidang Keilmuan", value: "bidang_keilmuan" },
    ],
    searchPublikasi: "",
    headersPublikasi: [
      { text: "No", value: "nomor" },
      { text: "Judul", value: "judul" },
      { text: "Jenis Publikasi", value: "jenis_publikasi" },
      {
        text: "Tanggal",
        value: "tanggal",
      },
      { text: "Kategori Kegiatan", value: "kategori_kegiatan" },
      { text: "Asal Data", value: "asal_data" },
      // { text: "Bidang Keilmuan", value: "bidang_keilmuan" },
    ],
    datas: [],
    pengabdian: [],
    publikasi: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getPengabdianDosenByID();
    },

    async getPengabdianDosenByID() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          "/api/pengabdianDosenByID/" + this.$route.params.id
        );
        this.datas = response.data[0];
        this.pengabdian = response.data[0].pengabdian;
        console.log(this.datas);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
