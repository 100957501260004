<template>
  <div>
    <v-container class="ma-0 pa-1 my-n3" grid-list-sm>
      <v-breadcrumbs :items="BreadItems">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header
                dark
                color="primary"
                style="color: white"
              >
                Explorer
                <template v-slot:actions>
                  <v-icon color="white"> mdi-chevron-down </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content> </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="12" md="8">
          <v-card>
            <v-toolbar dark color="primary" tile>
              <v-toolbar-title>Menu</v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pa-3">
                  <v-layout wrap class="justify-center">
                    <v-flex
                      v-for="menu in menuItems"
                      :key="`menu-` + menu.menu_id"
                      xs6
                      sm3
                      md3
                      lg2
                      class="pa-1 pa-md-3 pa-lg-3"
                    >
                      <menu-item :menu="menu" />
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container class="mb-2" grid-list-sm>
      <v-layout wrap>
        <v-flex
          v-for="menu in menuItems"
          :key="`menu-` + menu.menu_id"
          xs6
          sm6
          md3
          lg3
          class="pa-1 pa-md-2 pa-lg-2"
        >
          <menu-item :menu="menu" />
        </v-flex>
      </v-layout>
    </v-container> -->
  </div>
</template>

<script>
export default {
  name: "Menu",
  components: {
    MenuItem: () =>
      import(/* webpackChunkName: "menu-item" */ "@/components/MenuItem.vue"),
  },
  data: () => ({
    panel: [0],
    category: "", // objek category
    menuItems: [], // daftar buku pada category tersebut
    // page: 0,
    // lengthPage: 0,
    BreadItems: [],
  }),
  created() {
    this.go();
  },
  methods: {
    go() {
      this.$vloading.show();
      let { slug } = this.$route.params;
      let url = "/menu";
      // url = url + "?page=" + this.page;
      // url = encodeURI(url);
      this.axios
        .get(url)
        .then((response) => {
          this.$vloading.hide();
          var Data = response.data.data;
          var newData = Data.filter((e) => e.menu_parent == slug);
          this.menuItems = newData;
        })
        .catch((error) => {
          this.$vloading.hide();
          let { responses } = error;
          console.log(responses);
        });

      this.axios
        .get(url)
        .then((response) => {
          this.$vloading.hide();
          var Datas = response.data.data;
          var newDatas = Datas.filter((e) => e.menu_id == slug);
          this.BreadItems = [
            {
              text: "Home",
              disabled: false,
              to: "/",
            },
            {
              text: newDatas[0].menu_slug,
              disabled: true,
            },
          ];
          // console.log(newDatas);
        })
        .catch((error) => {
          this.$vloading.hide();
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>