<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-breadcrumbs :items="itemsBreadcrumbs" large>
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="12">
          <ComingSoon />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ComingSoon from "../../components/ComingSoon.vue";
export default {
  name: "Iku1",
  components: {
    ComingSoon,
  },
  data: () => ({
    itemsBreadcrumbs: [
      {
        text: "Data IKU",
        disabled: false,
        href: "#",
      },
      {
        text: "IKU 1",
        disabled: true,
        href: "#",
      },
    ],
  }),
  computed: {},
  created() {},

  methods: {},
};
</script>
