<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <a style="text-decoration: none">Layanan Kementerian</a
          ><v-icon small>mdi-chevron-right</v-icon
          ><span style="color: #939292">SISTER</span>
        </v-col>

        <v-col cols="12">
          <MyAlert
            deskripsi="SISTER merupakan singkatan dari Sistem Informasi Sumber Daya Terintegrasi, yang mana SISTER ini adalah program dari Kementrian Riset, Teknologi dan Pendidikan Tinggi atau yang biasa kita kenal dengan Kemenristekdikti yang diluncurkan melalui Dirktorat Jenderal Sumber Daya Iptek dan Dikti."
            judul="SISTER (Sistem Informasi Sumberdaya Terintegrasi)"
            url="https://sister.kemdikbud.go.id/"
          />
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <!-- <h2>Data SISTER</h2> -->
              <v-divider style="border-top: 5px solid #990000"></v-divider>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <MyCard2
                actions="Sertifikasi Dosen"
                bottom_color="#0288D1"
                color="light-blue"
                icon="mdi-card-account-details-star-outline"
                :title="sertifikasi_dosen.toLocaleString('en-US')"
                :url="
                  `/${$i18n.locale}` + '/kementerian/sister/sertifikasi-dosen'
                "
                method=""
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <MyCard2
                actions="Pendidikan dan Pengajaran"
                bottom_color="#F57C00"
                color="orange"
                icon="mdi-human-male-board-poll"
                :title="pendidikan_pengajaran.toLocaleString('en-US') + ' SKS'"
                :url="
                  `/${$i18n.locale}` +
                    '/kementerian/sister/pendidikan-pengajaran'
                "
                method=""
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <MyCard2
                actions="Penelitian dan Publikasi"
                bottom_color="#388E3C"
                color="green"
                icon="mdi-book-open-page-variant"
                :title="penelitian_publikasi.toLocaleString('en-US')"
                :url="
                  `/${$i18n.locale}` +
                    '/kementerian/sister/penelitian-publikasi'
                "
                method=""
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <MyCard2
                actions="Pengabdian pada Masyarakat"
                bottom_color="#D32F2F"
                color="red"
                icon="mdi-account-group"
                :title="pengabdian_masyarakat.toLocaleString('en-US')"
                :url="
                  `/${$i18n.locale}` +
                    '/kementerian/sister/pengabdian-masyarakat'
                "
                method=""
              />
            </v-col>
            <v-col cols="12">
              <p>
                <small>
                  <em
                    >*Terakhir diupdate pada 16 Okt
                    2023.</em
                  >
                </small>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br /><br /><br />
  </div>
</template>

<script>
import MyAlert from "../../../components/MyAlert.vue";
import MyCard2 from "../../../components/MyCard2.vue";

export default {
  name: "Sister",
  components: {
    MyAlert,
    MyCard2,
  },
  data: () => ({
    sertifikasi_dosen: 0,
    pendidikan_pengajaran: 0,
    penelitian_publikasi: 0,
    pengabdian_masyarakat: 0,
    tahun: new Date().getFullYear(),
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getSertifikasiDosenCount();
      this.getPengajaranDosenSKSTotal();
      this.getPenelitianPublikasiDosenCount();
      this.getPengabdianDosenCount();
    },

    async getSertifikasiDosenCount() {
      try {
        const response = await this.$axios.get("/api/sertifikasiDosenCount");
        this.sertifikasi_dosen = response.data.total_sertifikasi;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getPengajaranDosenSKSTotal() {
      try {
        const response = await this.$axios.get("/api/pengajaranDosenSKSTotal");
        this.pendidikan_pengajaran = response.data;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getPenelitianPublikasiDosenCount() {
      try {
        const response = await this.$axios.get(
          "/api/penelitianPublikasiDosenCount"
        );
        this.penelitian_publikasi = response.data;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getPengabdianDosenCount() {
      try {
        const response = await this.$axios.get("/api/pengabdianDosenCount");
        this.pengabdian_masyarakat = response.data;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
