<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs parent="akademik.data" sub_parent="akademik.jumlah" />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
          v-for="dt in itemsFakultas"
          :key="dt.fakultas"
        >
          <span v-if="dt.fakultas == 'PASCASARJANA'">
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              :actions="dt.fakultas"
              icon="mdi-account-group"
            />
          </span>
          <span
            v-else-if="
              dt.fakultas == 'FAKULTAS MATEMATIKA DAN ILMU PENGETAHUAN ALAM'
            "
          >
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              actions="MATEMATIKA DAN IPA"
              icon="mdi-account-group"
            />
          </span>
          <span v-else>
            <MyCard
              :color="dt.warna"
              :title="dt.count.toLocaleString('en-US')"
              :bottom_color="dt.warna2"
              :actions="dt.fakultas.substring(9)"
              icon="mdi-account-group"
            />
          </span>
        </v-col>

        <v-col cols="12">
          <v-card class="text-center" elevation="3">
            <v-card-actions
              style="background-color: #1e88e5"
              class="
                justify-center
                text-body-1 text-md-h6
                font-weight-bold
                white--text
              "
            >
              GRAFIK JUMLAH MAHASISWA PERTAHUN
            </v-card-actions>
            <v-card-title class="text-body-2 text-md-body-1">
              <v-container>
                <v-row>
                  <v-col cols="12" md="8" lg="8">
                    <div id="chart">
                      <apexchart
                        type="area"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4">
                    <v-data-table
                      :headers="headers"
                      :items="jumlahMahasiswa"
                      :items-per-page="5"
                      mobile-breakpoint="0"
                      sort-by="angkatan"
                      sort-desc="true"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                      }"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <!-- <v-card-actions
              class="justify-center text-body-1 text-md-h6 font-weight-bold"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="3" lg="3">
                    <MyCard
                      color="indigo"
                      actions="Total Mahasiswa"
                      bottom_color="#3F51B5"
                      icon="mdi-account-school"
                      title="60.711"
                    />
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <MyCard
                      color="indigo"
                      actions="Mahasiswa Aktif"
                      bottom_color="#3F51B5"
                      icon="mdi-book-education"
                      title="19.741"
                    />
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <MyCard
                      color="indigo"
                      actions="Mahasiswa Diterima T.A. 2022"
                      bottom_color="#3F51B5"
                      icon="mdi-clipboard-account"
                      title="4.962"
                    />
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <MyCard
                      color="indigo"
                      actions="Daya Tampung Mahasiswa Baru"
                      bottom_color="#3F51B5"
                      icon="mdi-database"
                      title="6.069"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyCard from "../../components/MyCard.vue";
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";

export default {
  name: "JumlahMahasiswa",
  components: {
    MyCard,
    MyBreadcrumbs,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    // itemsFakultas: [
    //   {
    //     fakultas: "Ekonomi",
    //     jumlah_mahasiswa: "1.500",
    //     color: "red",
    //     bottom_color: "#D32F2F",
    //   },
    //   {
    //     fakultas: "Hukum",
    //     jumlah_mahasiswa: "2.545",
    //     color: "pink",
    //     bottom_color: "#C2185B",
    //   },
    //   {
    //     fakultas: "Ilmu Pendidikan",
    //     jumlah_mahasiswa: "3.125",
    //     color: "purple",
    //     bottom_color: "#7B1FA2",
    //   },
    //   {
    //     fakultas: "Ilmu Sosial",
    //     jumlah_mahasiswa: "950",
    //     color: "light-blue",
    //     bottom_color: "#0288D1",
    //   },
    //   {
    //     fakultas: "Kedokteran",
    //     jumlah_mahasiswa: "800",
    //     color: "deep-purple",
    //     bottom_color: "#512DA8",
    //   },
    //   {
    //     fakultas: "FMIPA",
    //     jumlah_mahasiswa: "1.345",
    //     color: "cyan",
    //     bottom_color: "#0097A7",
    //   },
    //   {
    //     fakultas: "Olahraga dan Kesehatan",
    //     jumlah_mahasiswa: "1.412",
    //     color: "teal",
    //     bottom_color: "#00796B",
    //   },
    //   {
    //     fakultas: "Perikanan dan Ilmu Kelautan",
    //     jumlah_mahasiswa: "782",
    //     color: "green",
    //     bottom_color: "#388E3C",
    //   },
    //   {
    //     fakultas: "Pertanian",
    //     jumlah_mahasiswa: "900",
    //     color: "lime",
    //     bottom_color: "#AFB42B",
    //   },
    //   {
    //     fakultas: "Sastra dan Budaya",
    //     jumlah_mahasiswa: "1.445",
    //     color: "orange",
    //     bottom_color: "#f57c00",
    //   },
    //   {
    //     fakultas: "Teknik",
    //     jumlah_mahasiswa: "1.512",
    //     color: "brown",
    //     bottom_color: "#5D4037",
    //   },
    //   {
    //     fakultas: "Pascasarjana",
    //     jumlah_mahasiswa: "882",
    //     color: "blue-grey",
    //     bottom_color: "#455A64",
    //   },
    // ],

    itemsFakultas: [],

    series: [],
    chartOptions: [],

    headers: [
      { text: "Tahun", value: "angkatan" },
      { text: "Jumlah Mahasiswa", value: "count" },
    ],

    jumlahMahasiswa: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.countMahasiswaAktifByFakultas();
      this.countMahasiswaByTahun();
    },

    countMahasiswaAktifByFakultas() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaAktifByFakultas")
        .then((response) => {
          this.$vloading.hide();
          this.itemsFakultas = response.data.data;
          // console.log(response);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    countMahasiswaByTahun() {
      this.$vloading.show();
      this.axios
        .get("/api/countMahasiswaByTahun")
        .then((response) => {
          this.$vloading.hide();
          this.jumlahMahasiswa = response.data.data;
          // console.log(response);

          var data = response.data.data;

          const count = data.map((key) => {
            return key.count;
          });

          const angkatan = data.map((key) => {
            return key.angkatan;
          });

          this.series = [
            {
              name: "Jumlah Mahasiswa",
              data: count,
            },
          ];
          this.chartOptions = {
            chart: {
              type: "area",
              height: 350,
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },

            title: {
              text: "1995 - " + this.tahun,
              align: "center",
            },
            labels: angkatan,
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },
  },
};
</script>