<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <MyBreadcrumbs
            parent="kementerian.layanan"
            sub_parent="kementerian.siaga"
          />
        </v-col>

        <v-col cols="12">
          <MyAlert
            deskripsi="Aplikasi Sistem Informasi Kelembagaan (SIAGA) adalah layanan
              kelembagaan pendidikan tinggi akademik Direktorat Kelembagaan
              Ditjen Pendidikan Tinggi, Riset, dan Teknologi dengan sistem one
              stop service perizinan program studi, perubahan PTS, dan pendirian
              PTS serta layanan akademik lainnya."
            judul="kementerian.siaga"
            url="https://siaga.kemdikbud.go.id"
          />
        </v-col>

        <v-col cols="12">
          <ComingSoon />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyAlert from "../../components/MyAlert.vue";
import MyBreadcrumbs from "../../components/MyBreadcrumbs.vue";
import ComingSoon from "../../components/ComingSoon.vue";

export default {
  name: "Siaga",
  components: {
    MyBreadcrumbs,
    MyAlert,
    ComingSoon,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {},
  },
};
</script>