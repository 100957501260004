<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("akreditas.akreditas_prodi_terkini") }}</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12" md="4" lg="4" v-for="dt in count" :key="dt.id">
          <MyCard
            v-if="dt.akreditas == $route.params.id"
            color="green"
            :title="dt.count"
            bottom_color="#388E3C"
            :actions="'AKREDITASI ' + dt.akreditas"
            icon="mdi-seal-variant"
            text_color="white"
            :url="`/${$i18n.locale}` + '/akreditas/peringkat/' + dt.akreditas"
            :method="getData"
          />
          <MyCard
            v-else
            color="teal"
            :title="dt.count"
            bottom_color="#00796B"
            :actions="'AKREDITASI ' + dt.akreditas"
            icon="mdi-seal-variant"
            text_color="white"
            :url="`/${$i18n.locale}` + '/akreditas/peringkat/' + dt.akreditas"
            :method="getData"
          />
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-actions
              style="background-color: #00796B"
              class="
                justify-center
                text-body-1
                font-weight-bold
                white--text
              "
            >
              FILTER BY : AKREDITASI {{ $route.params.id }}
              <v-btn x-small class="ml-2" @click="loadData">reset filter</v-btn>
            </v-card-actions>
            <v-data-table
              :headers="headers"
              :items="datas"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:item.nomor="{ item }">
                {{ datas.indexOf(item) + 1 }}
              </template>
              <!-- <template v-slot:item.akreditas="{ item }">
              <v-chip color="success" label>{{ item.akreditas }}</v-chip>
            </template> -->
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  color="teal darken-2"
                  x-small
                  class="ma-2 white--text"
                  @click="riwayat(item)"
                >
                  Riwayat
                  <v-icon right dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  :label="$t('tabel.cari')"
                  class="mx-4 pt-4"
                ></v-text-field
              ></template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyCard from "@/components/MyCard.vue";
export default {
  name: "AkreditasByPeringkat",
  components: {
    MyCard,
  },
  data: () => ({
    tahun: new Date().getFullYear(),
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Program Studi", value: "program_studi" },
      { text: "Jenjang", value: "jenjang" },
      { text: "Akreditasi", value: "akreditas" },
      { text: "Aksi", value: "aksi" },
    ],
    datas: [],
    count: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getData();
      this.getCount();
    },

    async getData() {
      try {
        const response = await this.$axios.get(
          "/api/listAkreditasByAkreditas/" + this.$route.params.id
        );
        this.datas = response.data.data;
        // console.log(this.datas);
        // var Data = response.data.data.tahun2022;
        // var newData = Data.filter((e) => e.akreditas == this.$route.params.id);
        // this.datas = newData;
        // console.log(this.datas);
      } catch (error) {
        console.log(error);
      }
    },

    async getCount() {
      try {
        const response = await this.$axios.get("/api/countAkreditasByStatus");
        this.count = response.data;
        // console.log(this.count);
      } catch (error) {
        console.log(error);
      }
    },

    riwayat(item) {
      this.$router.push(
        "/id/akreditas/" + item.program_studi + "/jenjang/" + item.jenjang
      );
    },

    loadData() {
      this.$router.push("/id/akreditas");
    },
  },
};
</script>
