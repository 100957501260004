<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="ma-4">
          <a style="text-decoration: none">Layanan Kementerian</a
          ><v-icon small>mdi-chevron-right</v-icon
          ><span style="color: #939292">PDDikti</span>
        </v-col>

        <v-col cols="12">
          <MyAlert
            deskripsi="Pangkalan Data Pendidikan Tinggi atau PDDikti merupakan platform rujukan informasi untuk mengecek data perguruan tinggi di Indonesia. Platform ini dikelola langsung oleh Direktorat Jenderal Pendidikan Tinggi (Dirjen Dikti) di bawah Kementerian Pendidikan dan Kebudayaan (Kemendikbud)."
            judul="PDDikti - Pangkalan Data Pendidikan Tinggi"
            url="https://pddikti.kemdikbud.go.id/"
          />
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <!-- <h2>Data SISTER</h2> -->
              <v-divider style="border-top: 5px solid #990000"></v-divider>
            </v-col>
            <v-col cols="12" md="7" lg="7">
              <v-card :loading="isLoading">
                <v-card-actions
                  style="background-color: #990000"
                  class="
                justify-center
                text-body-1
                font-weight-bold
                white--text
              "
                >
                  PROGRAM STUDI
                </v-card-actions>
                <v-data-table
                  :headers="headers"
                  :items="datas"
                  :search="search"
                  mobile-breakpoint="0"
                >
                  <template v-slot:item.nomor="{ item }">
                    {{ datas.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.aksi="{ item }">
                    <v-btn
                      color="teal darken-2"
                      x-small
                      class="ma-2 white--text"
                      @click="detail(item)"
                    >
                      Detail
                      <v-icon right dark>
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      :label="$t('tabel.cari')"
                      class="mx-4 pt-4"
                    ></v-text-field
                  ></template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" lg="5">
              <v-card :loading="isLoading">
                <v-card-actions
                  style="background-color: #990000"
                  class="
                justify-center
                text-body-1
                font-weight-bold
                white--text
              "
                >
                  PERSENTASE PELAPORAN PDDIKTI TAHUN 2019-2022
                </v-card-actions>
                <br />
                <div id="chart2">
                  <apexchart
                    type="bar"
                    :options="chartOptions2"
                    :series="series2"
                  ></apexchart>
                  <br />
                </div>
              </v-card>
              <p>
                <small>
                  <em>*Terakhir diupdate pada 16 Okt 2023.</em>
                </small>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br /><br />
  </div>
</template>

<script>
import MyAlert from "../../../components/MyAlert.vue";

export default {
  name: "Pddikti",
  components: {
    MyAlert,
  },
  data: () => ({
    isLoading: false,
    tahun: new Date().getFullYear(),
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Kode", value: "kode" },
      { text: "Program Studi", value: "program_studi" },
      {
        text: "Status",
        value: "status",
      },
      { text: "Aksi", value: "aksi" },
    ],
    datas: [],
    series2: [
      {
        name: "Persentase Pelaporan",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chartOptions2: {
      chart2: {
        type: "bar",
      },
      colors: [
        "#4CAF50",
        "#FF9800",
        "#F44336",
        "#2196F3",
        "#9C27B0",
        "#795548",
        "#E91E63",
        "#607D8B",
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + "%";
        },
      },
      legend: {
        show: false,
      },
      title: {
        text: "Program Studi ",
        align: "center",
      },
      xaxis: {
        categories: [20191, 20192, 20201, 20202, 20211, 20212, 20221, 20222],
        labels: {
          style: {
            colors: [
              "#4CAF50",
              "#FF9800",
              "#F44336",
              "#2196F3",
              "#9C27B0",
              "#795548",
              "#E91E63",
              "#607D8B",
            ],
            fontSize: "12px",
          },
        },
      },
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getPDDIKTIList();
    },

    async getPDDIKTIList() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get("/api/PDDIKTIList");
        this.datas = response.data;
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    detail(item) {
      // console.log(item);
      this.series2 = [
        {
          name: "Persentase Pelaporan",
          data: [
            item.semester_20191,
            item.semester_20192,
            item.semester_20201,
            item.semester_20202,
            item.semester_20211,
            item.semester_20212,
            item.semester_20221,
            item.semester_20222,
          ],
        },
      ];
      this.chartOptions2 = {
        chart2: {
          type: "bar",
        },
        colors: [
          "#4CAF50",
          "#FF9800",
          "#F44336",
          "#2196F3",
          "#9C27B0",
          "#795548",
          "#E91E63",
          "#607D8B",
        ],
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val + "%";
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: "Program Studi " + item.program_studi,
          align: "center",
        },
        xaxis: {
          categories: [20191, 20192, 20201, 20202, 20211, 20212, 20221, 20222],
          labels: {
            style: {
              colors: [
                "#4CAF50",
                "#FF9800",
                "#F44336",
                "#2196F3",
                "#9C27B0",
                "#795548",
                "#E91E63",
                "#607D8B",
              ],
              fontSize: "12px",
            },
          },
        },
      };
    },
  },
};
</script>
