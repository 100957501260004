<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="mx-4 mt-4">
          <div>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.layanan")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <a @click="$router.back()" style="text-decoration: none">{{
              $t("kementerian.sister")
            }}</a>
            <v-icon small>mdi-chevron-right</v-icon>
            <a @click="$router.back()" style="text-decoration: none"
              >Penelitian Dan Publikasi
            </a>
            <v-icon small>mdi-chevron-right</v-icon>
            <span style="color: #939292">Detail</span>
          </div>
        </v-col>

        <v-col cols="12">
          <h2>{{ datas.nama }} ({{ datas.nidn }})</h2>
          <v-divider style="border-top: 5px solid #990000"></v-divider>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels focusable multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header style="background:#990000;color:white"
                >Penelitian
                <template v-slot:actions>
                  <v-icon color="white">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card :loading="isLoading" flat>
                  <v-data-table
                    :headers="headersPenelitian"
                    :items="penelitian"
                    :search="searchPenelitian"
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item.nomor="{ item }">
                      {{ penelitian.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.aksi="{ item }">
                      <v-btn
                        color="teal darken-2"
                        x-small
                        class="ma-2 white--text"
                        @click="riwayat(item)"
                      >
                        Detail
                        <v-icon right dark>
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:top>
                      <v-text-field
                        v-model="searchPenelitian"
                        :label="$t('tabel.cari')"
                        class="mx-4 pt-4"
                      ></v-text-field
                    ></template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header style="background:#990000;color:white"
                >Publikasi
                <template v-slot:actions>
                  <v-icon color="white">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card :loading="isLoading" flat>
                  <v-data-table
                    :headers="headersPublikasi"
                    :items="publikasi"
                    :search="searchPublikasi"
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item.nomor="{ item }">
                      {{ publikasi.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.aksi="{ item }">
                      <v-btn
                        color="teal darken-2"
                        x-small
                        class="ma-2 white--text"
                        @click="riwayat(item)"
                      >
                        Detail
                        <v-icon right dark>
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:top>
                      <v-text-field
                        v-model="searchPublikasi"
                        :label="$t('tabel.cari')"
                        class="mx-4 pt-4"
                      ></v-text-field
                    ></template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </div>
</template>

<script>
export default {
  name: "Sertifikasi_dosen",
  components: {},
  data: () => ({
    isLoading: false,
    panel: [0, 1],
    tahun: new Date().getFullYear(),
    searchPenelitian: "",
    headersPenelitian: [
      { text: "No", value: "nomor" },
      { text: "Judul", value: "judul" },
      { text: "Lama Kegiatan", value: "lama_kegiatan" },
      {
        text: "Tahun Pelaksanaan",
        value: "tahun_pelaksanaan",
      },
      // { text: "Bidang Keilmuan", value: "bidang_keilmuan" },
    ],
    searchPublikasi: "",
    headersPublikasi: [
      { text: "No", value: "nomor" },
      { text: "Judul", value: "judul" },
      { text: "Jenis Publikasi", value: "jenis_publikasi" },
      {
        text: "Tanggal",
        value: "tanggal",
      },
      { text: "Kategori Kegiatan", value: "kategori_kegiatan" },
      { text: "Asal Data", value: "asal_data" },
      // { text: "Bidang Keilmuan", value: "bidang_keilmuan" },
    ],
    datas: [],
    penelitian: [],
    publikasi: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getPenelitianPublikasiDosenByID();
    },

    async getPenelitianPublikasiDosenByID() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          "/api/penelitianPublikasiDosenByID/" + this.$route.params.id
        );
        this.datas = response.data[0];
        this.penelitian = response.data[0].penelitian;
        this.publikasi = response.data[0].publikasi;
        console.log(this.datas);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
